.margin-btm-common {
  margin-bottom: 10px;
}

.margin-right-prev {
  margin-right: 10px;
}

.text-left {
  text-align: left;
}

.map-syles {
  width: 80vw;
  height: 80vh;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.error-input {
  border: 2px solid red;
}

.map-size {
  max-width: 100%;
  align-items: center;
}

.slidecontainer {
  width: 100%;
}

.slider {
  width: 100%;
  align-items: center;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #505550;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #9fa39f;
  cursor: pointer;
}

.slider-tool {
  text-align: center;
  margin: .5rem;
}

.center-title {
  text-align: center;
  margin: .25rem;
}

.fetching-centered {
  text-align: center;
}

/*Media queries work down to 250px wide:*/
/*Minimize each with left menu dropdown arrows in your editor while editing*/
@media (max-width: 344px) {
  .progress-bar-titles {
    margin-bottom: 1rem;
    margin-left: 10px;
    color: black;
    width: 100%;
    height: 2rem;
    display: inline-grid;
    grid-template-columns: 1.5rem 12% 1rem 18% 1rem 15% 1rem;
    grid-template-rows: 1;
  }

  .progress-bar {
    margin-bottom: 1rem;
    background-color: white;
    width: 100%;
    height: 4rem;
    display: inline-grid;
    align-items: center;
    grid-template-columns: 2rem 10% 2rem 10% 2rem 10% 2rem;
    grid-template-rows: 1;
  }

  .separator {
    width: 20%;
  }

  .step-one-location {
    background-color: lightgrey;
    height: 2rem;
    border-radius: 2rem;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-one-location-title {
    height: 2rem;
    text-align: center;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-two-location {
    background-color: lightgrey;
    height: .5rem;
    width: 100%;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-three-location {
    background-color: lightgrey;
    height: 2rem;
    border-radius: 2rem;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-three-location-title {
    height: 2rem;
    text-align: center;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-four-location {
    background-color: lightgrey;
    height: .5rem;
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-five-location {
    background-color: lightgrey;
    height: 2rem;
    border-radius: 2rem;
    grid-column-start: 5;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-five-location-title {
    height: 2rem;
    text-align: center;
    grid-column-start: 5;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-six-location {
    background-color: lightgrey;
    height: .5rem;
    grid-column-start: 6;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-seven-location {
    background-color: lightgrey;
    height: 2rem;
    border-radius: 2rem;
    grid-column-start: 7;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-seven-location-title {
    height: 2rem;
    text-align: center;
    grid-column-start: 7;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 1;
  }  
}

@media (min-width: 345px) and (max-width: 434px) {
  .progress-bar-titles {
    margin-bottom: 2rem;
    margin-left: 3rem;
    color: black;
    width: 100%;
    height: 2rem;
    display: inline-grid;
    grid-template-columns: 1rem 20% 1rem 20% 1rem 10% 1rem;
    grid-template-rows: 1;
  }

  .progress-bar {
    margin-bottom: 1rem;
    background-color: white;
    width: 100%;
    height: 4rem;
    display: inline-grid;
    align-items: center;
    grid-template-columns: 2rem 16% 2rem 16% 2rem 16% 2rem;
    grid-template-rows: 1;
  }

  .separator {
    width: 20%;
  }

  .step-one-location {
    background-color: lightgrey;
    height: 2rem;
    border-radius: 2rem;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-one-location-title {
    height: 2rem;
    text-align: center;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-two-location {
    background-color: lightgrey;
    height: .5rem;
    width: 100%;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-three-location {
    background-color: lightgrey;
    height: 2rem;
    border-radius: 2rem;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-three-location-title {
    height: 2rem;
    text-align: center;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-four-location {
    background-color: lightgrey;
    height: .5rem;
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-five-location {
    background-color: lightgrey;
    height: 2rem;
    border-radius: 2rem;
    grid-column-start: 5;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-five-location-title {
    height: 2rem;
    text-align: center;
    grid-column-start: 5;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-six-location {
    background-color: lightgrey;
    height: .5rem;
    grid-column-start: 6;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-seven-location {
    background-color: lightgrey;
    height: 2rem;
    border-radius: 2rem;
    grid-column-start: 7;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-seven-location-title {
    height: 2rem;
    text-align: center;
    grid-column-start: 7;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 1;
  }  
}

@media (min-width: 435px) and (max-width: 519px) {
  .progress-bar-titles {
    margin-bottom: 2rem;
    margin-left: 1rem;
    color: black;
    width: 100%;
    height: 2rem;
    display: inline-grid;
    grid-template-columns: 1rem 25% 1rem 25% 1rem 15% 1rem;
    grid-template-rows: 1;
  }

  .progress-bar {
    margin-bottom: 1rem;
    background-color: white;
    width: 100%;
    height: 4rem;
    display: inline-grid;
    align-items: center;
    grid-template-columns: 2rem 22% 2rem 22% 2rem 22% 2rem;
    grid-template-rows: 1;
  }

  .separator {
    width: 20%;
  }

  .step-one-location {
    background-color: lightgrey;
    height: 2rem;
    border-radius: 2rem;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-one-location-title {
    height: 2rem;
    text-align: center;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-two-location {
    background-color: lightgrey;
    height: .5rem;
    width: 100%;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-three-location {
    background-color: lightgrey;
    height: 2rem;
    border-radius: 2rem;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-three-location-title {
    height: 2rem;
    text-align: center;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-four-location {
    background-color: lightgrey;
    height: .5rem;
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-five-location {
    background-color: lightgrey;
    height: 2rem;
    border-radius: 2rem;
    grid-column-start: 5;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-five-location-title {
    height: 2rem;
    text-align: center;
    grid-column-start: 5;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-six-location {
    background-color: lightgrey;
    height: .5rem;
    grid-column-start: 6;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-seven-location {
    background-color: lightgrey;
    height: 2rem;
    border-radius: 2rem;
    grid-column-start: 7;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-seven-location-title {
    height: 2rem;
    text-align: center;
    grid-column-start: 7;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 1;
  }  
}

@media (min-width: 520px) and (max-width: 774px) {
  .progress-bar-titles {
    margin-bottom: 2rem;
    margin-left: 2rem;
    color: black;
    width: 100%;
    height: 2rem;
    display: inline-grid;
    grid-template-columns: 2rem 24% 2rem 24% 2rem 15% 2rem;
    grid-template-rows: 1;
  }

  .progress-bar {
    margin-bottom: 1rem;
    background-color: white;
    width: 100%;
    height: 4rem;
    display: inline-grid;
    align-items: center;
    grid-template-columns: 2rem 24% 2rem 24% 2rem 24% 2rem;
    grid-template-rows: 1;
  }

  .separator {
    width: 20%;
  }

  .step-one-location {
    background-color: lightgrey;
    height: 2rem;
    border-radius: 2rem;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-one-location-title {
    height: 2rem;
    text-align: center;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-two-location {
    background-color: lightgrey;
    height: .5rem;
    width: 100%;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-three-location {
    background-color: lightgrey;
    height: 2rem;
    border-radius: 2rem;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-three-location-title {
    height: 2rem;
    text-align: center;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-four-location {
    background-color: lightgrey;
    height: .5rem;
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-five-location {
    background-color: lightgrey;
    height: 2rem;
    border-radius: 2rem;
    grid-column-start: 5;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-five-location-title {
    height: 2rem;
    text-align: center;
    grid-column-start: 5;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-six-location {
    background-color: lightgrey;
    height: .5rem;
    grid-column-start: 6;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-seven-location {
    background-color: lightgrey;
    height: 2rem;
    border-radius: 2rem;
    grid-column-start: 7;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-seven-location-title {
    height: 2rem;
    text-align: center;
    grid-column-start: 7;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 1;
  }  
}

@media (min-width: 775px) and (max-width: 991px) {
  .progress-bar-titles {
    margin-bottom: 2rem;
    margin-left: 1.5rem;
    color: black;
    width: 100%;
    height: 2rem;
    display: inline-grid;
    grid-template-columns: 2rem 27% 2rem 27% 2rem 20% 2rem;
    grid-template-rows: 1;
  }

  .progress-bar {
    margin-bottom: 1rem;
    background-color: white;
    width: 100%;
    height: 4rem;
    display: inline-grid;
    align-items: center;
    grid-template-columns: 2rem 27% 2rem 27% 2rem 27% 2rem;
    grid-template-rows: 1;
  }

  .separator {
    width: 20%;
  }

  .step-one-location {
    background-color: lightgrey;
    height: 2rem;
    border-radius: 2rem;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-one-location-title {
    height: 2rem;
    text-align: center;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-two-location {
    background-color: lightgrey;
    height: .5rem;
    width: 100%;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-three-location {
    background-color: lightgrey;
    height: 2rem;
    border-radius: 2rem;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-three-location-title {
    height: 2rem;
    text-align: center;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-four-location {
    background-color: lightgrey;
    height: .5rem;
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-five-location {
    background-color: lightgrey;
    height: 2rem;
    border-radius: 2rem;
    grid-column-start: 5;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-five-location-title {
    height: 2rem;
    text-align: center;
    grid-column-start: 5;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-six-location {
    background-color: lightgrey;
    height: .5rem;
    grid-column-start: 6;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-seven-location {
    background-color: lightgrey;
    height: 2rem;
    border-radius: 2rem;
    grid-column-start: 7;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-seven-location-title {
    height: 2rem;
    text-align: center;
    grid-column-start: 7;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 1;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .progress-bar {
    margin-bottom: 1rem;
    background-color: white;
    width: 100%;
    height: 4rem;
    display: inline-grid;
    align-items: center;
    grid-template-columns: 3rem 25% 3rem 25% 3rem 25% 3rem;
    grid-template-rows: 1;
  }

  .progress-bar-titles {
    margin-bottom: 2rem;
    margin-left: 3rem;
    color: black;
    width: 100%;
    height: 2rem;
    display: inline-grid;
    grid-template-columns: 3rem 25% 3rem 25% 3rem 23% 3rem;
    grid-template-rows: 1;
  }

  .separator {
    width: 20%;
  }

  .step-one-location {
    background-color: lightgrey;
    height: 3rem;
    border-radius: 3rem;
    padding-top: 15%;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-one-location-title {
    height: 2rem;
    text-align: center;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-two-location {
    background-color: lightgrey;
    height: .5rem;
    width: 100%;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-three-location {
    background-color: lightgrey;
    height: 3rem;
    border-radius: 3rem;
    padding-top: 15%;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-three-location-title {
    height: 2rem;
    text-align: center;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-four-location {
    background-color: lightgrey;
    height: .5rem;
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-five-location {
    background-color: lightgrey;
    height: 3rem;
    border-radius: 3rem;
    padding-top: 15%;
    grid-column-start: 5;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-five-location-title {
    height: 2rem;
    text-align: center;
    grid-column-start: 5;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-six-location {
    background-color: lightgrey;
    height: .5rem;
    grid-column-start: 6;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-seven-location {
    background-color: lightgrey;
    height: 3rem;
    border-radius: 3rem;
    padding-top: 15%;
    grid-column-start: 7;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-seven-location-title {
    height: 2rem;
    text-align: center;
    grid-column-start: 7;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 1;
  }
}

@media (min-width: 1201px) {
  .progress-bar {
    margin-bottom: 1rem;
    background-color: white;
    width: 100%;
    height: 4rem;
    display: inline-grid;
    align-items: center;
    grid-template-columns: 3rem 27.5% 3rem 27.5% 3rem 27.5% 3rem;
    grid-template-rows: 1;
  }

  .progress-bar-titles {
    margin-bottom: 2rem;
    margin-left: 1.5%;
    color: black;
    width: 100%;
    height: 2rem;
    display: inline-grid;
    align-items: center;
    grid-template-columns: 3rem 27.5% 3rem 27.5% 3rem 25% 3rem;
    grid-template-rows: 1;
  }

  .separator {
    width: 20%;
  }

  .step-one-location {
    background-color: lightgrey;
    height: 3rem;
    border-radius: 3rem;
    padding-top: 15%;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-one-location-title {
    height: 2rem;
    text-align: center;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-two-location {
    background-color: lightgrey;
    height: .5rem;
    width: 100%;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-three-location {
    background-color: lightgrey;
    height: 3rem;
    border-radius: 3rem;
    padding-top: 15%;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-three-location-title {
    height: 2rem;
    text-align: center;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-four-location {
    background-color: lightgrey;
    height: .5rem;
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-five-location {
    background-color: lightgrey;
    height: 3rem;
    border-radius: 3rem;
    padding-top: 15%;
    grid-column-start: 5;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-five-location-title {
    height: 2rem;
    text-align: center;
    grid-column-start: 5;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-six-location {
    background-color: lightgrey;
    height: .5rem;
    grid-column-start: 6;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-seven-location {
    background-color: lightgrey;
    height: 3rem;
    border-radius: 3rem;
    padding-top: 15%;
    grid-column-start: 7;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .step-seven-location-title {
    height: 2rem;
    text-align: center;
    grid-column-start: 7;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 1;
  }
}

.center-text {
  margin-top: 10%;
  margin-bottom: 20%;
  text-align: center;
}