@import '../../../Assets/css/mixins';

.table {
  margin-top: 16px;

  th {
      color: white;
      background-color: $medium_dark_green;
      text-align: center;
  }

  tr:hover {
    background-color: #d0d0d0;
  }

  td:hover {
      cursor: pointer;
  }
}

.table-striped tbody tr:nth-of-type(odd):hover {
  background-color: #d0d0d0;
}