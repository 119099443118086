@import '../../../Assets/css/mixins';

.gerx-menubar {
    display: flex;
    flex-direction: row;
    width: 100%;

    .navbar-brand {
      padding: 0;

      .logo-nav {
        max-width: 360px;
      }
    }

    .navbar {
        width: 100%;
        background-image: linear-gradient(to right, white, $lightgreen, $darkgreen);
    
        .nav-link {
            color: #e0e0e0;

            &:hover {
                color: white;
            }
        }

        .btn-link {
            font-size: small;
        }
    }
}