@import '../../Assets/css/mixins';

html, body {
  height: 100%;
}

.App-Privacy, .App-TermsAndConditions {
  text-align: left;
  padding-top: 1rem;
  padding-left: 20%;
  padding-right: 20%;
}

.App {
  padding-bottom: 80px;
}
.App .App-Content {
  min-height: 50vh;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
  padding: 10px;
}

.App-Footer {
  position: fixed;
  z-index: 1;
  bottom: 0;
  width: 100%;
  background-color: #282c34;
  min-height: 60px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;}

.App-Header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
}

.App-link {
  color: #61dafb;
}


nav {
  display: flex;
  justify-content: space-around;
  min-height: 5vh;
  color: #fff;
}

.Nav-links {
  width: 50%;
  display: flex;
  justify-content: space-around;
  text-decoration: none;
  list-style: none;
}


/* 
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} 

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/

.table {
  margin-top: 16px;

  th {
      color: white;
      background-color: $medium_dark_green;
      text-align: center;
  }

  tr:hover {
    background-color: #d0d0d0;
  }

  td:hover {
      cursor: pointer;
  }
}

.App-banner-success
{
  color: "#FFF";
  background-color: green;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14;
}

.App-banner-warning
{
  color: "#FFF";
  background-color: orange;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14;
}

.App-banner-error
{
  color: "#FFF";
  background-color: red;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14;
}