.inline-block {
  display: inline-block;
}

.map-btns {
  float: right;
}

.btn-margin {
  margin: .5rem;
}

.esri-icon-cursor {
  display: none;
}
.esri-sketch__section:first-child{
  padding: 0;
  margin: 0;
}

/* hide the search icon */
button.esri-search__submit-button.esri-widget--button {
  display: none;
}

.esri-icon-search {
  display: none;
}

/* hide the undo & redo buttons */
/* button.esri-sketch__button.esri-icon-redo {
  display:none;
}

button.esri-sketch__button.esri-icon-undo {
  display:none;
}

.esri-sketch__tool-section {
  border-right: 0px;
}

div.esri-sketch__panel > div:nth-child(3) {
  display: none;
} */